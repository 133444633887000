<template>
  <div data-aos="fade-up" data-aos-duration="600">
    <div class="wpo-breadcumb-area">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <div class="wpo-breadcumb-wrap">
              <h2>Blog</h2>
              <ul>
                <li><router-link to="/">Home</router-link></li>
                <li><span>Blog</span></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <section class="wpo-blog-single-section section-padding">
      <div class="container">
        <div class="row">
          <div class="col col-md-8">
            <div class="gooey" v-if="blog_data.length === 0" style="height: 500px">
              <img src="../assets/images/Infinity-loader.gif" alt="">
            </div>
            <div class="wpo-wpo-blog-content clearfix" v-if="blog_data.length !== 0">
              <div class="post" v-if="blog_data.status === 'published'">
                <div class="entry-media video-holder" v-if="blog_data.video_url">
                  <img :src="blog_data.image" @error="setAltImg" alt>
                  <a href="#" @click.prevent="openVideo(blog_data.video_url)" class="video-btn">
                    <i class="fi flaticon-play-button-2"></i>
                  </a>
                </div>
                <div class="entry-media" v-else>
                  <img :src="blog_data.image" @error="setAltImg" alt>
                </div>
                <ul class="entry-meta">
                  <li v-if="blog_data.author"><b>By &nbsp;</b> <a>{{blog_data.author}}</a></li>
                  <li v-if="blog_data.date"><a><i class="ti-calendar"></i> {{blog_data.date | moment("MMMM DD, YYYY")}}</a></li>
                </ul>
                <h2>{{blog_data.title}}</h2>
                <p v-html="blog_data.text"></p>
              </div>
            </div>
          </div>
          <div class="col col-md-4">
            <blogLeftContent />
          </div>
        </div>
      </div> <!-- end container -->
    </section>

    <modal name="video" height="90%" width="80%">
      <div class="video-modal">
        <LazyYoutube :src="modalVideo" maxWidth="100%" />
      </div>
    </modal>
  </div>
</template>

<script>
    import blogLeftContent from '../components/blog-left-content';
    import api from "../services/api";
    const vueLazytube = require('vue-lazytube');
    const { LazyYoutube } = vueLazytube;

    export default {
        name: "blog-details",
        data() {
          return {
              blog_data: [],
              modalVideo: '',
          }
        },
        methods: {
            async init () {
                if (this.$route.params.url) {
                    this.blog_data = await api.blogDetail(this.$route.params.url);
                    this.blog_data = this.blog_data.data[0];
                    if (typeof this.blog_data.image === "number") {
                        this.blog_data.image = await api.getFiles(this.blog_data.image);
                        this.blog_data.image = this.blog_data.image.data['data']['full_url'];
                    }
                } else {
                    this.$router.push({ name: '/' });
                }
            },
            openVideo(video) {
                this.$modal.show('video');
                this.modalVideo = video;
            },
            setAltImg(event) {
                event.target.src = require("@/assets/images/default-image.jpg")
            },
        },
        components: {
            blogLeftContent,
            LazyYoutube
        },
        watch: {
            '$route.params': {
                immediate: true,
                handler() {
                    window.scrollTo(0,0);
                    this.init();
                }
            }
        },
    }
</script>

<style scoped>
  .entry-media {
    width: 100%;
    height: 446px;
    background-image: linear-gradient(to right, #f725845e, #7108b778);
  }
  .entry-media img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .gooey {
    width: 100%;
    height: 400px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .video-modal {
    padding: 10px;
    width: 100%;
    height: 100%;
  }
  iframe {
    border: none;
  }
  .video-holder {
    position: relative;
    text-align: center;
  }
  .video-holder a {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: #fff;
    width: 83px;
    height: 83px;
    border-radius: 83px;
    display: inline-block;
    -webkit-animation: spineer 2s infinite;
    animation: spineer 2s infinite;
    z-index: 99;
  }
  .video-holder:after {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    content: "";
    background: rgba(69, 69, 69, .4);
  }

  @media (max-width: 991px) {
    .video-holder a {
      width: 65px;
      height: 65px;
      border-radius: 65px;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      -moz-transform: translate(-50%, -50%);
      -o-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }

  .video-holder a:before {
    content: "";
    width: 0px;
    height: 0px;
    border-top: 15px solid transparent;
    border-bottom: 15px solid transparent;
    border-left: 20px solid #062265;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }

</style>
